.top_btns[data-v-111c941e] {
  margin-bottom: 40px;
}
.top_btns .left_box[data-v-111c941e] {
  float: left;
}
.top_btns .right_box[data-v-111c941e] {
  float: right;
  text-align: right;
}
.filter-item1[data-v-111c941e] {
  margin-right: 10px;
}
.el-table__column-filter-trigger i[data-v-111c941e] {
  color: #fff !important;
  width: 10px;
}
.el-table__column-filter-trigger .el-icon-arrow-down[data-v-111c941e] {
  color: white !important;
  width: 10px;
}
.none-border .div_p[data-v-111c941e] {
  border-bottom: 1px solid #eee;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.none-border .div_p[data-v-111c941e]:last-child {
  border-bottom: none;
}
.item_icon[data-v-111c941e] {
  cursor: pointer;
}
.item_icon i[data-v-111c941e] {
  display: inline-block;
  line-height: 13px;
  font-size: 13px;
  margin-right: 4px;
}
.item_icon .el-icon-circle-check[data-v-111c941e] {
  color: #67c23a;
}
.item_icon .iconfont icon-cuowu-outline[data-v-111c941e] {
  color: #e6a23c;
}
.timeJe[data-v-111c941e] {
  display: flex;
  justify-content: space-around;
}
.export_btn[data-v-111c941e] {
  position: absolute;
  top: 8px;
  right: 18px;
  z-index: 99;
}
