.item_icon[data-v-1e102f7f] {
  display: inline-block;
  cursor: pointer;
  font-size: 13px;
}
.item_icon i[data-v-1e102f7f] {
  display: inline-block;
  font-size: 13px;
  margin-right: 4px;
}
.item_icon p[data-v-1e102f7f] {
  display: inline-block;
  font-size: 13px;
  color: #333;
}
.top_btns[data-v-1e102f7f] {
  margin-bottom: 40px;
}
.top_btns .left_box[data-v-1e102f7f] {
  float: left;
}
.top_btns .right_box[data-v-1e102f7f] {
  float: right;
  text-align: right;
}
.filter-item1[data-v-1e102f7f] {
  margin-right: 10px;
}
.el-table th[data-v-1e102f7f] {
  padding: 0px;
}
.el-table td[data-v-1e102f7f] {
  padding: 0px;
}
.el-table__column-filter-trigger .el-icon-arrow-down[data-v-1e102f7f] {
  color: white !important;
  width: 10px;
}
.none-border .div_p[data-v-1e102f7f] {
  line-height: 30px;
  border-bottom: 1px solid #efefef;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.none-border .div_p[data-v-1e102f7f]:last-child {
  border-bottom: none;
}
.export_btn[data-v-1e102f7f] {
  position: absolute;
  top: 8px;
  right: 18px;
  z-index: 99;
}
[data-v-1e102f7f] textarea {
  min-height: 145px !important;
}
.el-message__content a[data-v-1e102f7f] {
  text-decoration: underline;
  color: #409eff;
}
